import React from 'react'
import Entry from 'Lib/entry'

const ShowEntries = ({ entries }) => {
  return (
    <div className='list-group'>
      {entries.map((entry, key) => (
        <Entry entry={entry} key={key} showOrderLink={false} />
      ))}
    </div>
  )
}

export default ShowEntries
