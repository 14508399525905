import React, { useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import Fetch from 'Lib/fetch'
import Currency from 'Lib/currency'

const ConfirmPayment = ({ payment, mutate }) => {
  const [date, setDate] = useState(null)

  const logPayment = () => {
    Fetch(`/admin/payments/${payment.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        payment: {
          paid_at: date
        }
      })
    }).then(response => {
      if (response.ok) {
        if (mutate) mutate()
      } else {
        alert(`Error - could not save payment info`)
      }
    })
  }

  return (
    <div className='p-3 d-flex flex-column border bg-light'>
      <div className='d-flex flex-column align-items-start mb-2 pb-2 border-bottom'>
        <div className='fw-bold me-auto'>Payment details for bank</div>
        <div className='me-2 text-success'>Reference: {payment.reference}</div>
        <div className='me-2 text-success'>
          Sort code / Account: {payment.sort_code} / {payment.account_number}
        </div>
        <div className='me-2 text-success'>
          Account name: {payment.account_name}{' '}
        </div>
        <div>
          Amount: <Currency className='text-success' amount={payment.amount} />
        </div>
      </div>
    </div>
  )
}

export default ConfirmPayment
