import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Fetch from 'Lib/fetch'

const PaymentCancel = ({ paymentId }) => {
  const [cancelling, setCancelling] = useState(false)

  const cancel = () => {
    setCancelling(true)
    Fetch(`/payments/${paymentId}/cancel`, {
      method: 'DELETE'
    }).then(response => {
      if (response.ok) {
        window.location.href = `/checkout`
      } else {
        alert(`Error cancelling payment ${paymentId}`)
        setCancelling(false)
      }
    })
  }

  return (
    <button
      type='button'
      class='btn btn-danger'
      onClick={cancel}
      disabled={cancelling}
    >
      {cancelling && (
        <FontAwesomeIcon icon={faCircleNotch} spin className='me-2' />
      )}
      {cancelling ? 'Cancelling...' : 'Yes cancel'}
    </button>
  )
}

export default PaymentCancel
