import React from 'react'
import Expander from 'Lib/expander'
import Currency from 'Lib/currency'
import PaymentHeader from './payment-header'
import Entry from 'Lib/entry'
import Invoice from '../invoice'

const Payment = ({ payment }) => {
  return (
    <Expander title={<PaymentHeader payment={payment} />}>
      <div className='f-flex flex-col'>
        <div className='fw-bold'>Entries</div>
        {payment.entries.map(entry => (
          <Entry entryId={entry.id} key={entry.id} showOrderLink={false} />
        ))}
        <a
          href={`/payments/${payment.id}`}
          className='btn btn-sm btn-primary mt-3'
        >
          View order details
        </a>
      </div>
    </Expander>
  )
}

export default Payment
