import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Entry from 'Lib/entry'
import Currency from 'Lib/currency'
import { mrfcMagenta } from 'Css/colors.scss'
import User from './user'

const Checkout = ({}) => {
  const { data: entries, mutate } = useSWR('/entries/not_entered', Fetcher)
  const { data: currentUser, mutate: mutateUser } = useSWR('/me', Fetcher)

  const canCheckout =
    currentUser &&
    currentUser.first_name &&
    currentUser.last_name &&
    currentUser.phone

  const basketTotal =
    entries &&
    entries.reduce((total, entry) => {
      total += parseFloat(entry.fee)

      return total
    }, 0)

  console.log('basketTotal', basketTotal)

  return (
    <div className='d-flex flex-column p-3'>
      {!entries && !currentUser && (
        <div className='d-flex align-items-center'>
          <FontAwesomeIcon icon={faCircleNotch} spin className='me-2' />
          loading...
        </div>
      )}
      {entries && currentUser && (
        <>
          <User user={currentUser} mutate={mutateUser} complete={canCheckout} />
          {entries.length === 0 && (
            <div className='alert alert-info'>
              You have no entries in your basket
            </div>
          )}
          {entries.length > 0 && (
            <div>
              <div className='alert alert-info'>
                Confirming the following entries (
                <Currency amount={basketTotal} /> to pay)
              </div>
              <div className='list-group'>
                {entries
                  .sort((a, b) =>
                    `${a.tournament}${a.team_name}` >
                    `${b.tournament}${b.team_name}`
                      ? 1
                      : -1
                  )
                  .map((entry, key) => (
                    <Entry
                      entry={entry}
                      key={key}
                      mutate={mutate}
                      showOrderLink={false}
                    />
                  ))}
              </div>
            </div>
          )}
          {basketTotal > 0 && canCheckout && (
            <>
              <div className='p-3 bg-light border d-flex flex-column flex-sm-row'>
                <a
                  className='btn btn-dark mb-3 mb-sm-0 ms-0 ms-sm-auto'
                  href='/take_card_payment'
                  style={{
                    backgroundColor: mrfcMagenta,
                    borderColor: mrfcMagenta
                  }}
                >
                  Pay by <Currency amount={basketTotal} /> by Credit/Debit Card
                  or Bank Transfer
                </a>
              </div>
              <div>
                <p className='p-2'>
                  Please note if paying by bank transfer{' '}
                  <strong>our bank details have changed</strong> so please
                  ensure you follow the provided instructions and do not used a
                  saved payment destination.
                </p>
              </div>
              <div className='mt-3' style={{ fontSize: '0.8rem' }}>
                Payments made by credit card are processed by{' '}
                <a href='https://stripe.com' target='_blank'>
                  Stripe
                </a>{' '}
                a global highly secure payments provider.
              </div>
              <div style={{ fontSize: '0.9rem' }}>
                Personal data provided above will be used by us (Maidenhead RFC)
                solely for the purposes of the administration of the Maidenhead
                2021 Minis festival and your entry into it.
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Checkout
